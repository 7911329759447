import styled, { css } from 'styled-components';
import { Flex, Text as text } from '@kiper/ui';

export const EmptyStateContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  align-items: center;

  @media screen and (min-width: 1366px) {
    gap: 8px;
  }

  @media screen and (min-width: 1920px) {
    gap: 16px;
  }
`;

export const Img = styled.img`
  @media screen and (min-width: 1366px) {
    ${props =>
      props.notfound
        ? css`
            width: 120px;
          `
        : css`
            width: 260px;
          `}
  }

  @media screen and (min-width: 1920px) {
    ${props =>
      props.notfound
        ? css`
            width: 260px;
          `
        : css`
            width: 320px;
          `}
  }
`;

export const TitleEmptyState = styled(text)`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  text-align: center;
  color: ${props => props.theme.colors.highBlack};

  @media screen and (min-width: 1366px) {
    font-size: 20px;
    line-height: 27px;
  }

  @media screen and (min-width: 1920px) {
    font-size: 24px;
    line-height: 33px;
  }
`;

export const TextEmptyState = styled(text)`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: ${props => props.theme.colors.highBlack};

  @media screen and (min-width: 1366px) {
    font-size: 14px;
    line-height: 19px;
  }

  @media screen and (min-width: 1920px) {
    font-size: 16px;
    line-height: 22px;
  }
`;
