import { useContext, useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Flex, Input, ErrorMessage } from '@kiper/ui';
import { getGreetingTime } from '@kiper/fns';
import { useGuidedAttendance } from '../../../hooks';
import { GuidedAttendanceContext, TriageContext } from '../../../store';
import { Label } from '../styles';
import StepStructure, { StepTextTypeColor } from '../StepStructure';

const StepAttendanceGetName = () => {
  const { setContextTree } = useContext(GuidedAttendanceContext);
  const { setTriageContext } = useContext(TriageContext);
  const { handleNextAction } = useGuidedAttendance();
  const { values, setFieldValue, errors, touched } = useFormikContext();
  const { t } = useTranslation('guided-attendance');
  const schema = yup.object({
    stepAttendanceGetName: yup.object({
      name: yup
        .string()
        .trim()
        .min(3, t('common:feedback.min', { number: 3 }))
        .required(t('common:feedback.required-field')),
      oldName: yup
        .string()
        .trim()
        .nullable(),
    }),
  });

  const greet = useMemo(() => getGreetingTime(new Date()), []); // TODO: Esperar vir o GMT do eventSingle pra passar o segundo param do getGreetingTime

  const nextAction = async value => {
    if (value?.stepAttendanceGetName?.name)
      setTriageContext({
        identifier: value.stepAttendanceGetName.name,
      });

    const handleNextFn = await handleNextAction({
      values: value,
      fieldValue: 'stepAttendanceGetName',
    });

    return handleNextFn;
  };

  useEffect(() => {
    if (!values?.stepAttendanceGetName?.name) {
      setFieldValue('stepAttendanceGetName', { name: '', oldName: null });
    }
    setContextTree({
      formikSchema: schema,
      nextAction: async stepData => {
        const nextStep = await nextAction(stepData);
        return { nextStep, currentStepData: stepData };
      },
    });
  }, []);

  return (
    <StepStructure
      type={StepTextTypeColor.SPEAK}
      text={t('step-get-name.title', { period: t(`step-get-name.${greet}`) })}
      hasNextStepButton
    >
      <Flex flexDirection="column" width="100%">
        <Label>{t('step-get-name.label')}</Label>
        <Input
          placeholder={t('step-get-name.placeholder')}
          name="stepAttendanceGetName.name"
          value={values?.stepAttendanceGetName?.name}
          onChange={setFieldValue}
          autoFocus
          autoComplete="off"
          invalid={
            !!touched?.stepAttendanceGetName &&
            !!errors?.stepAttendanceGetName?.name
          }
        />
        {!!touched?.stepAttendanceGetName?.name &&
          !!errors?.stepAttendanceGetName?.name && (
            <ErrorMessage>{errors.stepAttendanceGetName.name}</ErrorMessage>
          )}
      </Flex>
    </StepStructure>
  );
};

export default StepAttendanceGetName;
