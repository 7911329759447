import { Fragment } from 'react';
import { Badge, Divider, Flex, Label } from '@kiper/ui';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatDateTimeUtc } from '@kiper/fns';

const TemporaryAccessItem = ({ t, value }) => {
  return (
    <Flex gridGap="8px" p="16px" flexDirection="column" width="100%">
      <Flex gridGap="4px" justifyContent="flex-start" width="100%">
        {value.place.map(place => (
          <Badge
            fontColor="primary500"
            color="white"
            bordercolor="primary500"
            key={place.id}
          >
            {place.name}
          </Badge>
        ))}
      </Flex>
      <Flex justifyContent="space-between" flex="1">
        <Flex flexDirection="column">
          <Label info block>
            {t('put.access-data.start')}
          </Label>
          <Label $withoutMarginBottom>
            {formatDateTimeUtc(value.startDate)}
          </Label>
        </Flex>
        <Flex flexDirection="column">
          <Label info block>
            {t('put.access-data.end')}
          </Label>
          <Label $withoutMarginBottom>{formatDateTimeUtc(value.endDate)}</Label>
        </Flex>
      </Flex>
    </Flex>
  );
};

const PlaceTemporaryAccess = ({ values }) => {
  const [t] = useTranslation('user');

  return (
    <Flex flexDirection="column" gridGap="8px" pb="24px">
      <Label info block $withoutMarginBottom>
        {t('put.access-data.place-temporary-access')}
      </Label>
      <Flex flexDirection="column">
        {values.map((value, index, self) => (
          <Fragment key={value.id}>
            <TemporaryAccessItem t={t} value={value} />
            {index < self.length - 1 && <Divider />}
          </Fragment>
        ))}
      </Flex>
    </Flex>
  );
};

TemporaryAccessItem.propTypes = {
  t: propTypes.func.isRequired,
  value: propTypes.shape({
    place: propTypes.arrayOf(
      propTypes.shape({
        id: propTypes.number.isRequired,
        name: propTypes.string.isRequired,
      }),
    ).isRequired,
    startDate: propTypes.string.isRequired,
    endDate: propTypes.string.isRequired,
  }).isRequired,
};

PlaceTemporaryAccess.propTypes = {
  values: propTypes.arrayOf({
    id: propTypes.number.isRequired,
    place: propTypes.arrayOf(
      propTypes.shape({
        id: propTypes.number.isRequired,
        name: propTypes.string.isRequired,
      }),
    ).isRequired,
    startDate: propTypes.string.isRequired,
    endDate: propTypes.string.isRequired,
  }),
};

PlaceTemporaryAccess.defaultProps = {
  values: [],
};

export default PlaceTemporaryAccess;
