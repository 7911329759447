import { useEffect, useRef } from 'react';
import { Label } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { useLazyQuery } from 'react-apollo';
import { dweller as dwellerGql } from '@kiper/monitoring-graphql';
import { runtimeConfig } from '@kiper/fns';
import { useFormikContext } from 'formik';
import ProfileSelect from '../../../../../../components/ProfileSelect';
import { FormGroup } from '../../../styles';

const usePrevious = value => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

const Profile = ({ values, handleChange, isEditable, indexAccesses }) => {
  const [t] = useTranslation('user');
  const { values: fatherValues } = useFormikContext();

  const profileId = useRef(
    fatherValues.accesses[indexAccesses]?.profile?.value,
  );

  const previousProfile = usePrevious(profileId);

  const [getFeatures] = useLazyQuery(
    dwellerGql.register.queries.dwellerAccessDataProfileFeatures,
    {
      fetchPolicy: 'no-cache',
      variables: {
        profileId: values.profile.value,
        applicationKey: runtimeConfig.APPLICATION_KEY_APP,
      },
      onCompleted: ({ dwellerAccessDataProfileFeatures }) => {
        const features = dwellerAccessDataProfileFeatures.map(x => ({
          ...x,
          ableToReadIsUpdated: false,
          ableToWriteIsUpdated: false,
        }));

        features.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;

          return 0;
        });

        profileId.current = values.profile.value;
        handleChange('permissions', features);
      },
    },
  );

  useEffect(() => {
    if (
      (values.profile?.value && !values.permissions?.length) ||
      (previousProfile && profileId.current !== previousProfile && isEditable)
    ) {
      getFeatures();
    }
  }, [profileId.current]);

  const handleChangeProfile = e => {
    profileId.current = Number(e.value);

    handleChange('profile', {
      label: (e && e.label) || '',
      value: (e && Number(e.value)) || '',
    });
  };

  return (
    <FormGroup>
      <Label style={{ fontSize: '14px' }} block>
        {t('put.access-data.user-profile')}
      </Label>
      {isEditable && (
        <Label info block>
          {t('put.access-data.profile-info')}
        </Label>
      )}
      <ProfileSelect
        id={`user-profile-select-${indexAccesses}`}
        placeholder={t('put.access-data.profile-select')}
        isEditable={isEditable}
        name="profile"
        value={values.profile}
        onChange={handleChangeProfile}
        width="100%"
      />
    </FormGroup>
  );
};

export default Profile;

Profile.propTypes = {
  values: propTypes.object.isRequired,
  isEditable: propTypes.bool.isRequired,
  handleChange: propTypes.func.isRequired,
  indexAccesses: propTypes.number.isRequired,
};
