import { Fragment, useEffect, useMemo, useContext } from 'react';
import propTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useMutation, useQuery } from 'react-apollo';
import { queries } from '@kiper/monitoring-graphql/triage';
import { eventTriage } from '@kiper/monitoring-graphql/attendance';
import { Trans, useTranslation } from 'react-i18next';
import { useSwal } from '@kiper/hooks';
import * as MdIcon from 'react-icons/md';
import { ErrorMessage, Flex, Spinner, Text } from '@kiper/ui';
import { Form } from 'reactstrap';
import { apolloErrorHandler, getGreetingTime, getUserLang } from '@kiper/fns';
import { TriageContext } from '../../../../store';
import { sendAmplitudeData } from '../../../../services/amplitude';
import Camera from './Camera';
import Questions from './Questions';
import {
  useCurrentLoggedContext,
  useGuidedAttendance,
  useRemoteConfig,
} from '../../../../hooks';

import {
  Button,
  ContentWrapper,
  CameraWrapper,
  OptionsContainer,
  Title,
  TitleIcon,
  FlexContent,
  ContinueButton,
  ArrowFoward,
  InfoText,
} from './styles';

const PREFIX_TRANSLATE = 'details.triage';

const triageId = {
  janitor: 10,
  others: 9,
  serviceProvision: 8,
  residentEntry: 7,
  orderDelivery: 6,
  mail: 5,
  foodDelivery: 4,
  exit: 3,
  visits: 2,
  skip: 1,
};

const triageType = Object.freeze({
  LEGACY: 'legacy',
  NEW: 'new',
  LEGACY_AND_NEW: 'legacyAndNew',
});

const EventTriage = ({ formikBag, completeTriage, event, toggleTab }) => {
  const { fillAction, setTriageContext } = useContext(TriageContext);

  const { toast } = useSwal();
  const [t] = useTranslation('event_attendance');
  const { loggedContext } = useCurrentLoggedContext();
  const { handleValidHasSubtriage } = useGuidedAttendance();

  let validation = {
    triage: yup
      .number()
      .min(1, t('details.triage.form.validations.triage'))
      .required(),
    log: yup.string().nullable(),
  };

  let initialValues = {
    triage: 0,
    log: '',
  };

  const showTriageQuestions = useRemoteConfig({
    path: 'attendance.triageQuestions',
    condominium: Number(event?.condominium?.personContext?.id),
    user: Number(loggedContext?.personContextId),
  });

  const isOutGoingAttendance = useRemoteConfig({
    path: 'attendance.outGoingAttendance',
    condominium: Number(event?.device?.id),
    partner: Number(loggedContext?.partner?.personContextId),
    user: Number(loggedContext?.personContextId),
  });

  if (showTriageQuestions) {
    validation = {
      ...validation,
      identifier: yup.string(),
      guUn: yup.object().nullable(true),
      dweller: yup.string(),
    };

    initialValues = {
      ...initialValues,
      identifier: '',
      guUn: '',
      dweller: '',
    };
  }

  const { data, loading } = useQuery(queries.triages, {
    fetchPolicy: 'no-cache',
  });

  const [fetch, { loading: loadingSubmit }] = useMutation(eventTriage, {
    onCompleted: () => {
      completeTriage();
      toast.fire({
        title: t(`${PREFIX_TRANSLATE}.form.success`),
        icon: 'success',
      });
    },
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const hasSubtriage = useMemo(() => {
    return handleValidHasSubtriage(event?.condominium?.personContext);
  }, [event]);

  const handleFilterTriage = triage => {
    const { serializedParams } = triage;
    if (!serializedParams) return false;

    const params = JSON.parse(serializedParams);

    const isLegacyAndNew = params?.triageType === triageType.LEGACY_AND_NEW;
    const isLegacy = params?.triageType === triageType.LEGACY;
    const isNew = params?.triageType === triageType.NEW;

    if (hasSubtriage) return isLegacyAndNew || isNew;

    return isLegacyAndNew || isLegacy;
  };

  const triages = useMemo(() => {
    return (
      data?.triages
        ?.filter(triage => handleFilterTriage(triage))
        ?.map(x => {
          const Icon = MdIcon[x.icon];
          return {
            ...x,
            icon: <Icon />,
          };
        }) || []
    );
  }, [data]);

  const onSubmit = values => {
    const serializedTriage = JSON.stringify({
      identifier: values?.identifier,
      guUnTreeNodeId: values?.guUn?.treeNodeId || null,
      dweller: values?.dweller,
    });

    const newReport = formikBag?.values?.report?.length
      ? `${formikBag?.values?.report}\n${values?.log}`
      : values?.log;

    if (newReport) {
      let guUn = '     ';
      if (values?.guUn) {
        guUn = `${values?.guUn?.label || ''} - ${values?.guUn?.gu?.label ||
          ''}`;
      }
      formikBag.setFieldValue(
        'report',
        t(newReport, {
          identifier: values?.identifier || '     ',
          gu_un: guUn,
          dweller: values?.dweller || '     ',
        }),
      );
    }

    const variables = {
      eventId: event.eventId,
      triageId: values?.triage,
    };

    if (showTriageQuestions) {
      variables.serializedTriage = serializedTriage;
    }

    fetch({
      variables,
    }).then(() => {
      sendAmplitudeData('triage submit', {
        eventId: event.eventId,
        triageId: values?.triage,
        identifier: values?.identifier || '',
        guUnTreeNodeId: values?.guUn?.value || '',
        dweller: values?.dweller || '',
      });
      if (values?.guUn && +values?.triage !== triageId.exit)
        toggleTab('dwellers');
    });
  };

  const { values, setFieldValue, handleSubmit, touched, errors } = useFormik({
    initialValues,
    validationSchema: yup.object(validation),
    onSubmit,
  });

  const handleChangeOptionTriage = triage => {
    const serializedParams = JSON.parse(triage?.serializedParams);
    const triageFilterRulesIds = serializedParams?.condoRuleCategoryFilter
      ? serializedParams.condoRuleCategoryFilter
      : [];
    const triageFilterText = serializedParams?.condoRuleCategoryTextFilter
      ? serializedParams.condoRuleCategoryTextFilter[
          getUserLang().split('-')[0]
        ]
      : '';

    setFieldValue('triage', +triage?.id);
    setFieldValue('log', triage?.textLog || '');

    setTriageContext({
      triage,
      filterRulesIds: triageFilterRulesIds,
      filterText: triageFilterText,
    });

    fillAction('triage', event.eventId);

    sendAmplitudeData('triage change choice', {
      eventId: event?.eventId,
      triageId: +triage?.id,
    });
  };

  useEffect(() => {
    if (!!triages?.length && isOutGoingAttendance) {
      const outGoingTriage = triages.find(triage => triage.id === '3');
      handleChangeOptionTriage(outGoingTriage);
    }
  }, [triages]);

  const greet = useMemo(() => getGreetingTime(new Date()), []); // TODO: Esperar vir o GMT do eventSingle pra passar o segundo param do getGreetingTime

  const { isVideocall, unityTreeNodeId } = useMemo(
    () => ({
      isVideocall: event?.additionalInformation?.videoCall,
      unityTreeNodeId: event?.additionalInformation?.unityTreeNodeId,
    }),
    [event],
  );

  useEffect(() => {
    if (unityTreeNodeId) {
      setTriageContext({
        filterGUUnit: unityTreeNodeId,
      });
    }
  }, [unityTreeNodeId]);

  return (
    <FlexContent>
      <ContentWrapper>
        <CameraWrapper>
          <Text fontSize="32px">
            <Trans>
              {t(`${PREFIX_TRANSLATE}.greeting`, {
                period: t(`${PREFIX_TRANSLATE}.${greet}`),
              })}
            </Trans>
          </Text>
          <Camera event={event} />
        </CameraWrapper>
        <Form onSubmit={handleSubmit} autoComplete="off">
          <Flex mr="8px" ml="5px">
            <Title>
              <TitleIcon>
                <MdIcon.MdArrowForward />
              </TitleIcon>
              {t(`${PREFIX_TRANSLATE}.title`)}
            </Title>
          </Flex>
          <OptionsContainer>
            {loading && (
              <Flex justifyContent="center" width="100%" py="20px">
                <Spinner />
              </Flex>
            )}
            {!loading &&
              !!triages.length &&
              triages.map(triage => (
                <Fragment key={triage.id}>
                  <Button
                    key={+triage.id}
                    checked={+triage.id === +values?.triage}
                    value={+triage.id}
                    variant="out"
                    color={
                      +triage.id === +values?.triage ? 'primary' : 'neutral'
                    }
                    name="triage"
                    onChange={() => handleChangeOptionTriage(triage)}
                    label={triage.description}
                    icon={triage.icon}
                    id={`triage-option-${triage.id}`}
                    title={triage.description}
                  />
                </Fragment>
              ))}
          </OptionsContainer>
          {!!touched?.triage && !!errors?.triage && (
            <ErrorMessage>{errors.triage}</ErrorMessage>
          )}
          {showTriageQuestions && (
            <Questions
              setFieldValue={setFieldValue}
              event={event}
              values={values}
              touched={touched}
              errors={errors}
            />
          )}
          <Flex
            justifyContent="flex-start"
            alignItems="center"
            mt="8px"
            mr="7px"
            flexWrap="wrap"
          >
            {isVideocall && unityTreeNodeId && (
              <InfoText>
                <Flex p={12}>
                  <MdIcon.MdErrorOutline size={30} />
                </Flex>
                {t(`${PREFIX_TRANSLATE}.filter-information`)}
              </InfoText>
            )}
            <ContinueButton
              variant="out"
              color="secondary"
              type="submit"
              active={!!values?.triage}
              loading={loadingSubmit}
              disabled={loadingSubmit}
            >
              {t(`${PREFIX_TRANSLATE}.form.buttons.submit`)}
              <ArrowFoward />
            </ContinueButton>
          </Flex>
        </Form>
      </ContentWrapper>
    </FlexContent>
  );
};

export default EventTriage;

EventTriage.propTypes = {
  formikBag: propTypes.shape({
    setFieldValue: propTypes.func,
    values: propTypes.object,
  }).isRequired,
  completeTriage: propTypes.func.isRequired,
  event: propTypes.object.isRequired,
  toggleTab: propTypes.func.isRequired,
};
