import { useEffect, useMemo } from 'react';
import { useLazyQuery, useQuery } from 'react-apollo';
import propTypes from 'prop-types';
import { documentTypes as documentTypesGql } from '@kiper/monitoring-graphql/dweller/detail/query';
import { person as personGql } from '@kiper/monitoring-graphql';
import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { MdAdd, MdRemove } from 'react-icons/md';
import Document from './Document';
import { IconButton, Flex } from './styles';

const Documents = ({
  editMode,
  savedDocuments,
  onRedirectClick,
  checkPerson,
}) => {
  const [t] = useTranslation('user');
  const { toast, confirm } = useSwal();
  const {
    values,
    setFieldValue,
    errors,
    status,
    setStatus,
    setFieldError,
  } = useFormikContext();

  const { data, loading } = useQuery(documentTypesGql, {
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const [checkPersonExistByDocument] = useLazyQuery(
    personGql.checkPersonExistByDocument,
    {
      fetchPolicy: 'no-cache',
      onCompleted: async ({ checkPersonExistByDocument: userInfo }) => {
        const shouldRedirect = await confirm({
          title: t('put.info-check-exist-user.title', {
            userName: userInfo.name,
          }),
          text: t('put.info-check-exist-user.text'),
          cancelButtonText: t('put.info-check-exist-user.cancel'),
          confirmButtonText: t('put.info-check-exist-user.confirm'),
        });

        if (shouldRedirect) onRedirectClick(userInfo);
      },
    },
  );

  const documents = useMemo(() => {
    if (!values.documents) return [];
    return values.documents.filter(({ isDeleted }) => !isDeleted);
  }, [values.documents]);

  const { allowedDocumentTypes, canAddDocument } = useMemo(() => {
    const docTypes = data?.documentTypes ?? [];

    const allowed = docTypes.filter(documentType => {
      const hasDocType = documents?.some(
        doc => doc && doc.documentTypeId === documentType.id,
      );
      return !hasDocType;
    });

    const canAdd =
      allowed.length > 0 &&
      documents.filter(doc => !doc.documentTypeId).length < allowed.length;

    return {
      allowedDocumentTypes: allowed,
      canAddDocument: canAdd,
    };
  }, [data, documents]);

  const changeDocument = ({ attr, value, index }) => {
    const newDocuments = [
      ...documents,
      ...values.documents.filter(({ isDeleted }) => isDeleted),
    ];
    let newDocument = { ...newDocuments[index], [attr]: value };

    if (newDocument.id) newDocument = { ...newDocument, isUpdated: true };

    newDocuments[index] = newDocument;
    setFieldValue('documents', newDocuments);
  };

  const addDocument = () => {
    setFieldValue('documents', [
      ...documents,
      { documentTypeId: '', value: '', isInserted: true },
      ...values.documents.filter(({ isDeleted }) => isDeleted),
    ]);
  };

  const validateDocument = ({ index, isValid }) => {
    const newDocumentStatus = status?.documents || [];

    if (!isValid) {
      const has = newDocumentStatus.some(docIndex => docIndex === index);
      if (!has)
        setStatus({
          ...status,
          documents: [...newDocumentStatus, index],
        });
    } else {
      setStatus({
        ...status,
        documents: newDocumentStatus.filter(docIndex => docIndex !== index),
      });
    }
  };

  useEffect(() => {
    if (!errors?.documents && status?.documents.length) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < status.documents.length; i++) {
        const docIndex = status.documents[i];
        setFieldError(
          `documents[${docIndex}].value`,
          t('feedback.document-mask'),
        );
      }
    }
  }, [errors, status]);

  const removeDocument = index => {
    const newDocuments = [
      ...documents,
      ...values.documents.filter(({ isDeleted }) => isDeleted),
    ];

    if (newDocuments[index].id)
      newDocuments[index] = { ...newDocuments[index], isDeleted: true };
    else newDocuments.splice(index, 1);

    setFieldValue('documents', newDocuments);
    validateDocument({ index, isValid: true });
  };

  useEffect(() => {
    if (editMode && !values.documents?.length) {
      setFieldValue('documents', [
        {
          documentTypeId: '',
          value: '',
          isInserted: true,
        },
      ]);
    } else if (values.documents?.length) {
      setFieldValue('documents', savedDocuments);
    }
  }, [editMode]);

  const handleBlurDocument = index => {
    const { value = '', documentTypeId = null } =
      values?.documents[index] || {};

    if (!documentTypeId || !value) return;

    if (checkPerson)
      checkPersonExistByDocument({
        variables: {
          value,
          type: documentTypeId,
        },
      });
  };

  return (
    <>
      {!loading &&
        documents.map((document, index) => (
          <Flex alignItems="center" key={index}>
            <Document
              editMode={editMode}
              onBlur={() => handleBlurDocument(index)}
              documentTypes={data?.documentTypes || []}
              allowedDocumentTypes={allowedDocumentTypes}
              documentTypeId={document?.documentTypeId ?? ''}
              documentValue={document?.value ?? ''}
              handleChange={value => changeDocument({ ...value, index })}
              validateByMask={isValid => validateDocument({ index, isValid })}
              index={index}
            />
            {editMode && canAddDocument && index === documents.length - 1 && (
              <IconButton
                size="sm"
                color="primary"
                variant="out"
                rounded
                onClick={addDocument}
                disabled={errors?.documents?.length}
              >
                <MdAdd />
              </IconButton>
            )}
            {editMode && documents.length > 1 && (
              <IconButton
                size="sm"
                color="danger"
                variant="out"
                rounded
                onClick={() => removeDocument(index)}
              >
                <MdRemove />
              </IconButton>
            )}
          </Flex>
        ))}
    </>
  );
};

Documents.propTypes = {
  editMode: propTypes.bool.isRequired,
  savedDocuments: propTypes.array,
  onRedirectClick: propTypes.func.isRequired,
  checkPerson: propTypes.bool,
};

Documents.defaultProps = {
  savedDocuments: [],
  checkPerson: true,
};

export default Documents;
