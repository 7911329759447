import { isValidDate } from '@kiper/fns';
import * as yup from 'yup';

const treeNodeSchema = required => {
  if (required)
    return yup.object({
      value: yup.string().required(),
      id: yup.number().nullable(),
      name: yup.string().nullable(),
    });

  return yup
    .object({
      value: yup.string(),
      id: yup.number().nullable(),
      name: yup.string().nullable(),
    })
    .nullable();
};

export const timeSchema = yup.object().shape({
  hours: yup.string().required(),
  minutes: yup.string().required(),
  time: yup.string().required(),
});

const intervalTimeSchema = yup.object().shape({
  id: yup.number().nullable(),
  start: timeSchema.required(),
  end: timeSchema.required(),
  daysOfWeek: yup
    .array(yup.bool())
    .required()
    .test(x => x.some(y => y)),
});

const permissionsSchema = yup.object().shape({
  applicationFeatureId: yup.number(),
  ableToRead: yup.bool().nullable(),
  ableToWrite: yup.bool().nullable(),
  name: yup.string(),
  allowsEdit: yup.bool(),
  ableToReadIsUpdated: yup.bool(),
  ableToWriteIsUpdated: yup.bool(),
});

const bookingTimeSchema = required => {
  let schema = {
    initDate: yup
      .string()
      .test({ test: val => val && isValidDate(new Date(val)) }),
    endDate: yup
      .string()
      .test({ test: val => val && isValidDate(new Date(val)) }),
  };

  if (required) {
    schema = {
      initDate: schema.initDate.required(),
      endDate: schema.endDate.required(),
    };
  }

  return schema;
};

export const accessDataSchema = ({ isFieldRequired, t }) =>
  yup.object({
    id: yup.number(),
    profile: treeNodeSchema(true),
    isBlocked: yup.bool(),
    blockedReason: yup.string(),
    condominium: treeNodeSchema(true).required(),
    unityGroup: yup
      .mixed()
      .when('profile', ({ value }) =>
        isFieldRequired('unityGroup', value)
          ? treeNodeSchema(true).required()
          : treeNodeSchema(),
      ),
    unity: yup
      .mixed()
      .when('profile', ({ value }) =>
        isFieldRequired('unity', value)
          ? treeNodeSchema(true).required()
          : treeNodeSchema(),
      ),
    accessProfiles: yup.mixed().when('profile', ({ value }) =>
      isFieldRequired('accessProfile', value)
        ? yup
            .array()
            .of(treeNodeSchema(true).required())
            .required(t('common:feedback.required-field'))
        : yup.array().of(treeNodeSchema()),
    ),
    allowedAccesses: yup.mixed().when('profile', ({ value }) =>
      isFieldRequired('allowedAccesses', value)
        ? yup

            .array()
            .of(intervalTimeSchema)
            .required()
        : yup
            .array()
            .of(intervalTimeSchema)
            .nullable(),
    ),
    contactSchedule: yup
      .array()
      .of(intervalTimeSchema)
      .nullable(),
    bookingTime: yup.mixed().when('profile', ({ value }) =>
      isFieldRequired('bookingTime', value)
        ? yup
            .object()
            .shape(() => bookingTimeSchema(true))
            .nullable()
        : yup
            .object()
            .shape(() => bookingTimeSchema(false))
            .nullable(),
    ),
    permissions: yup.mixed().when('profile', ({ value }) =>
      isFieldRequired('features', value)
        ? yup
            .array()
            .of(permissionsSchema)
            .nullable()
            .required()
        : yup
            .array()
            .of(permissionsSchema)
            .nullable(),
    ),
    accessibilityTime: yup.number().nullable(),
    callPriority: yup.number().nullable(),
    observation: yup.string().nullable(),
    contactOrder: yup.object().nullable(),
  });
