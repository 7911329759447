import { useCallback, useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useLazyQuery } from 'react-apollo';

import { Flex, Table, Text } from '@kiper/ui';
import { TableContainer } from './styles';

const renderListItemDefault = props => {
  return (
    <tr ref={props?.trRef} {...props}>
      {props?.index}
    </tr>
  );
};

const InfiniteScrollList = ({
  columns,
  variables,
  query,
  renderListItem,
  onCompletedCallback,
  onErrorCallback,
}) => {
  const [state, setState] = useState([]);
  const [loader, setLoader] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);

  const { t } = useTranslation();

  const observer = useRef();

  const [fetchMore] = useLazyQuery(query, {
    fetchPolicy: 'no-cache',
    onCompleted: res => {
      setState(prevData => {
        if (prevData.length < 1) {
          return Object.values(res)[0]?.collection;
        }
        return [
          ...new Set([...prevData, ...Object.values(res)[0]?.collection]),
        ];
      });

      const pagination = Object.values(res)[0]?.pagination;

      setHasMore(pagination?.totalPages > pagination?.page);

      if (onCompletedCallback) onCompletedCallback(res);

      setLoader(false);
    },
    onError: err => {
      if (onErrorCallback) onErrorCallback(err);

      setLoader(false);
    },
  });

  useEffect(() => {
    setLoader(true);
    fetchMore({
      variables: {
        ...variables,
        filters: { ...variables.filters, page },
      },
    });
  }, [page, variables?.filters?.searchText, variables?.filters?.sourceNodeId]);

  useEffect(() => {
    setState([]);
    setPage(1);
  }, [variables?.filters?.searchText, variables?.filters?.sourceNodeId]);

  const lastBookElementRef = useCallback(
    node => {
      if (loader) return;

      if (!hasMore) return;

      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          setPage(f => f + 1);
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [loader, hasMore],
  );

  return (
    <TableContainer>
      <Table columns={columns} hover={false}>
        <tbody>
          {state.map((item, index) => {
            if (state.length === index + 1) {
              return renderListItem({
                trRef: lastBookElementRef,
                item,
                index,
                key: index,
              });
            }
            return renderListItem({
              item,
              index,
              key: index,
            });
          })}
          {loader && (
            <tr>
              <td colSpan={12}>
                <Flex justifyContent="center">
                  <Text color="secondary400" fontSize="12px">
                    {t('common:loading')}
                  </Text>
                </Flex>
              </td>
            </tr>
          )}

          {!state?.length && !loader && !hasMore && (
            <tr>
              <td colSpan={12}>
                <Flex justifyContent="center">
                  <Text color="secondary400" fontSize="12px">
                    {t('common:no-items-found')}
                  </Text>
                </Flex>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default InfiniteScrollList;

InfiniteScrollList.propTypes = {
  renderListItem: propTypes.func,
  variables: propTypes.object,
  query: propTypes.object,
  columns: propTypes.any.isRequired,
  onCompletedCallback: propTypes.func,
  onErrorCallback: propTypes.func,
};

InfiniteScrollList.defaultProps = {
  renderListItem: renderListItemDefault,
  variables: null,
  query: null,
  onCompletedCallback: null,
  onErrorCallback: null,
};
