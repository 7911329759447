import gql from 'graphql-tag';

export const attendanceStepFragment = gql`
  fragment attendanceStepFieldsParts on AttendanceStep {
    id
    componentName
  }
`;

export const recursiveAttendanceStepsFragment = gql`
  fragment recursiveAttendanceSteps on AttendanceStep {
    actions
  }
  ${attendanceStepFragment}
`;

export const guidedAttendanceFragment = gql`
  fragment guidedAttendanceParts on GuidedAttendance {
    attendanceStepId
    attendanceStep {
      ...attendanceStepFieldsParts
      ...recursiveAttendanceSteps
    }
  }
  ${attendanceStepFragment}
  ${recursiveAttendanceStepsFragment}
`;

export const guidedAttendanceTreeFragment = gql`
  fragment guidedAttendanceTreeParts on GuidedAttendanceResponse {
    tree: guidedAttendance {
      ...guidedAttendanceParts
    }
    context
  }
  ${guidedAttendanceFragment}
`;

export const condominiumDwellersFragment = gql`
  fragment condominiumDwellersParts on CondominiumDwellers {
    id
    name
    personContextId
  }
`;

export const condominiumUnityGroupFragment = gql`
  fragment condominiumUnityGroupParts on CondominiumUnityGroup {
    id
    description
    treeNodeId
  }
`;

export const condominiumUnitiesFragment = gql`
  fragment condominiumUnitiesParts on CondominiumUnities {
    id
    description
    treeNodeId
    unityGroup {
      ...condominiumUnityGroupParts
    }
    dwellers {
      ...condominiumDwellersParts
    }
  }
  ${condominiumUnityGroupFragment}
  ${condominiumDwellersFragment}
`;

export const unityDwellersResponseFragment = gql`
  fragment unityDwellersResponseParts on UnityDwellersResponse {
    unities {
      ...condominiumUnitiesParts
    }
  }
  ${condominiumUnitiesFragment}
`;

export const callPriorityProfileFragment = gql`
  fragment callPriorityProfileParts on CallPriorityProfile {
    id
    name
    fieldName
  }
`;

export const callPriorityPhoneFragment = gql`
  fragment callPriorityPhoneParts on CallPriorityPhone {
    destinationKind
    destination
    codAta
  }
`;

export const callPriorityFragment = gql`
  fragment callPriorityParts on CallPriority {
    personContextId
    name
    callPriority
    uniTyId
    observation
    phones {
      ...callPriorityPhoneParts
    }
    profile {
      ...callPriorityProfileParts
    }
  }
  ${callPriorityPhoneFragment}
  ${callPriorityProfileFragment}
`;

export const continuousAttendanceAttendantFragment = gql`
  fragment continuousAttendanceAttendantParts on ContinuousAttendanceAttendant {
    id
    name
    email
  }
`;

export const continuousAttendanceUnityFragment = gql`
  fragment continuousAttendanceUnityParts on ContinuousAttendanceUnity {
    id
    name
  }
`;

export const continuousAttendanceFragment = gql`
  fragment continuousAttendanceParts on ContinuousAttendance {
    eventId
    status
    identifier
    dweller
    creationDate
    triageId
    placeName
    treatment
    unityGroup {
      ...continuousAttendanceUnityParts
    }
    unity {
      ...continuousAttendanceUnityParts
    }
    attendant {
      ...continuousAttendanceAttendantParts
    }
  }
  ${continuousAttendanceUnityFragment}
  ${continuousAttendanceAttendantFragment}
`;

export const continuousAttendanceResponseFragment = gql`
  fragment continuousAttendanceResponseParts on ContinuousAttendanceResponse {
    attendances {
      ...continuousAttendanceParts
    }
  }
  ${continuousAttendanceFragment}
`;

export const preRegisterAuthorizationFragment = gql`
  fragment preRegisterAuthorizationParts on PreRegisterAuthorization {
    name
    type
    status
    observation
    profileName
    guestId
    personContextId
    transientPersonId
    hasRegisteredFace
    phoneNumber
    vehiclePlate
    documentCpf
    companyName
  }
`;

export const transientPersonResponseFragment = gql`
  fragment transientPersonResponseParts on TransientPersonResponse {
    id
    name
  }
`;

export const transientPersonSettingFragment = gql`
  fragment transientPersonSettingParts on TransientPersonSetting {
    id
    name
    type
    order
    isRequired
  }
`;

export const sideMenuFooterViewTemplateFragment = gql`
  fragment sideMenuFooterViewTemplateParts on SideMenuFooterViewTemplate {
    componentName
  }
`;

export const guidedAttendanceViewTemplateSideMenuFragment = gql`
  fragment guidedAttendanceViewTemplateSideMenuParts on GuidedAttendanceViewTemplateSideMenu {
    menu
    footer {
      ...sideMenuFooterViewTemplateParts
    }
  }
  ${sideMenuFooterViewTemplateFragment}
`;

export const guidedAttendanceViewTemplateDetailInfoFragment = gql`
  fragment guidedAttendanceViewTemplateDetailInfoParts on GuidedAttendanceViewTemplateDetailInfo {
    componentName
  }
`;

export const guidedAttendanceViewTemplateFragment = gql`
  fragment guidedAttendanceViewTemplateParts on GuidedAttendanceViewTemplate {
    eventType
    detailInfo {
      ...guidedAttendanceViewTemplateDetailInfoParts
    }
    sideMenu {
      ...guidedAttendanceViewTemplateSideMenuParts
    }
  }
  ${guidedAttendanceViewTemplateDetailInfoFragment}
  ${guidedAttendanceViewTemplateSideMenuFragment}
`;

export const lastAttendancesByEventTypeFragment = gql`
  fragment lastAttendancesByEventTypeParts on LastAttendancesByEventTypeTemplate {
    additionalInformation
    eventId
    attendant {
      ...continuousAttendanceAttendantParts
    }
    status
    creationDate
  }
  ${continuousAttendanceAttendantFragment}
`;

export const condominiumCameraLiveFragment = gql`
  fragment condominiumCameraLiveParts on CondominiumCameraLive {
    url
  }
`;
export const condominiumCameraLiveV2Fragment = gql`
  fragment condominiumCameraLiveV2Parts on CondominiumCameraLiveV2 {
    placeId
    placeName
    url
  }
`;

const dwellerDocumentFragment = gql`
  fragment dwellerDocumentParts on DwellerDocument {
    type
    value
  }
`;

export const dwellersWithDocumentsResponseFragment = gql`
  fragment dwellersWithDocumentsResponseParts on DwellersWithDocumentsResponse {
    id
    name
    documents {
      ...dwellerDocumentParts
    }
  }
  ${dwellerDocumentFragment}
`;

const condominiumRuleQuestionAnswerFragment = gql`
  fragment condominiumRuleQuestionAnswerParts on CondominiumRuleQuestionAnswer {
    questionAnswerType
    yesNoAnswer
    scheduleAnswer
    textAnswer
    condominiumId
    condoRuleQuestionId
    id
  }
`;

export const condominiumRuleQuestionFragment = gql`
  fragment condominiumRuleQuestionParts on CondominiumRuleQuestion {
    id
    description
    subtriageId
    order
    answer {
      ...condominiumRuleQuestionAnswerParts
    }
  }
  ${condominiumRuleQuestionAnswerFragment}
`;

const descriptionTranslateFragment = gql`
  fragment descriptionTranslateParts on DescriptionTranslate {
    pt
    es
    en
  }
`;
const activityTriageFragment = gql`
  fragment activityTriageParts on ActivityTriage {
    id
    description {
      ...descriptionTranslateParts
    }
  }
  ${descriptionTranslateFragment}
`;

const activitySubtriageFragment = gql`
  fragment activitySubtriageParts on ActivitySubtriage {
    id
    description {
      ...descriptionTranslateParts
    }
  }
  ${descriptionTranslateFragment}
`;

export const condominiumRuleQuestionsWithTriageAndSubtriageFragment = gql`
  fragment condominiumRuleQuestionsWithTriageAndSubtriageParts on CondominiumRuleQuestionsWithTriageAndSubtriage {
    triage {
      ...activityTriageParts
    }
    subtriage {
      ...activitySubtriageParts
    }
    questionsAndAnswer {
      ...condominiumRuleQuestionParts
    }
  }
  ${condominiumRuleQuestionFragment}
  ${activityTriageFragment}
  ${activitySubtriageFragment}
`;
