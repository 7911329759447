import propTypes from 'prop-types';
import { Flex } from '@kiper/ui';
import { Trans } from 'react-i18next';
import * as S from './styles';
import emptyStateImage from '../../../assets/images/empty-state-step-get-unity.svg';

const StepGetUnityEmptyState = ({ title, text, optionsNotFound }) => {
  return (
    <S.EmptyStateContainer>
      <S.Img
        src={emptyStateImage}
        alt="empty-state-map"
        notfound={optionsNotFound}
      />
      <Flex flexDirection="column" gridGap="8px">
        <Trans>
          <S.TitleEmptyState>{title}</S.TitleEmptyState>
          <S.TextEmptyState>{text}</S.TextEmptyState>
        </Trans>
      </Flex>
    </S.EmptyStateContainer>
  );
};

export default StepGetUnityEmptyState;

StepGetUnityEmptyState.propTypes = {
  title: propTypes.string.isRequired,
  text: propTypes.string.isRequired,
  optionsNotFound: propTypes.bool,
};

StepGetUnityEmptyState.defaultProps = {
  optionsNotFound: false,
};
