import { useCallback } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { monitoringDwellers } from '@kiper/monitoring-graphql/dweller/list/query';
import UsersHostsRow from './UsersHostsRow';
import InfiniteScrollList from '../../../InfiniteScrollList/InfiniteScrollList';

function UsersHosts({
  event,
  formik,
  setSourceNode,
  filters,
  handleChange,
  selectRef,
  toggleTab,
}) {
  const [t] = useTranslation('user');

  const unityTreeNodeId = event?.additionalInformation?.unityTreeNodeId;
  const callLogId = event?.additionalInformation?.callLogId;
  const showVideoCallStatus =
    callLogId &&
    !!unityTreeNodeId &&
    unityTreeNodeId === Number(filters?.sourceNodeId);

  const videocallStatusColumn = showVideoCallStatus
    ? [
        {
          name: t('list.table-header.videocall-status'),
          align: 'left',
          width: '20%',
        },
      ]
    : [];

  const columns = [
    {
      name: t('list.table-header.unity-group'),
      align: 'left',
      width: '15%',
    },
    {
      name: t('list.table-header.name'),
      align: 'left',
      width: !showVideoCallStatus ? '60%' : '40%',
    },
    {
      name: t('list.table-header.priority'),
      align: 'left',
      width: '10%',
    },
    {
      name: t('list.table-header.call'),
      align: 'left',
      width: '10%',
    },
    ...videocallStatusColumn,
    {
      name: t('list.table-header.actions'),
      align: 'left',
      width: '10%',
    },
  ];

  const getQueryVariables = useCallback(() => {
    const variables = {
      filters: {
        ...filters,
        sourceNodeId:
          Number(filters?.sourceNodeId) ||
          event?.condominium?.personContext?.treeNodeId,
      },
    };

    if (callLogId) {
      variables.callLogId = callLogId;
    }

    return variables;
  }, [callLogId, filters]);

  return (
    <InfiniteScrollList
      columns={columns}
      query={monitoringDwellers}
      variables={getQueryVariables()}
      renderListItem={listProps => (
        <UsersHostsRow
          formik={formik}
          event={event}
          setSourceNode={setSourceNode}
          selectRef={selectRef}
          handleSearchInput={handleChange}
          toggleTab={toggleTab}
          showVideoCallStatus={showVideoCallStatus}
          {...listProps}
        />
      )}
    />
  );
}

export default UsersHosts;

UsersHosts.propTypes = {
  event: propTypes.object.isRequired,
  formik: propTypes.object.isRequired,
  setSourceNode: propTypes.func.isRequired,
  filters: propTypes.any.isRequired,
  handlePageChange: propTypes.func.isRequired,
  handleChange: propTypes.func.isRequired,
  selectRef: propTypes.any.isRequired,
  toggleTab: propTypes.func.isRequired,
};
