import propTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Flex, MdIcons, Spinner, Text } from '@kiper/ui';
import { useForm, useNextAction, useQueries } from './hooks';
import { sendAmplitudeData } from '../../../services/amplitude';
import { NextStepButton } from '../ActionStepButtons';
import StepStructure, { StepTextTypeColor } from '../StepStructure';
import * as S from './styles';

const PREFIX = 'step-out-of-hours-service-provider';

const StepOutOfHoursServiceProvider = ({ event }) => {
  const { t } = useTranslation('guided-attendance');

  const { nextAction } = useNextAction();

  const { handleSubmit } = useForm({ nextAction });

  const { handleGetInfo, loading } = useQueries({
    condominiumPersonContextId: event?.condominium?.personContextId,
    translate: key => t(`${PREFIX}.${key}`),
  });

  const handleSendAmplitude = () => {
    sendAmplitudeData('essential service click', event);
    handleSubmit();
  };

  return (
    <StepStructure
      type={StepTextTypeColor.WARNING}
      text={t(`${PREFIX}.hours-not-permitted`)}
      hasPreviousStepButton
    >
      <Flex flexDirection="column" gridGap="16px" width="100%">
        <Flex flexDirection="column" gridGap="8px">
          {!loading ? (
            <S.BadgeAction color="info100">
              <MdIcons size="20px" mdIconName="info" />
              <Trans>
                <Text>{handleGetInfo()}</Text>
              </Trans>
            </S.BadgeAction>
          ) : (
            <Flex justifyContent="center" p="8px">
              <Spinner size={16} />
            </Flex>
          )}
        </Flex>
        <Flex gridGap="8px">
          <Button
            variant="out"
            color="neutral"
            size="sm"
            onClick={handleSendAmplitude}
          >
            {t(`${PREFIX}.essential-service`)}
          </Button>
          <NextStepButton />
        </Flex>
      </Flex>
    </StepStructure>
  );
};

export default StepOutOfHoursServiceProvider;
StepOutOfHoursServiceProvider.propTypes = {
  event: propTypes.shape({
    condominium: propTypes.shape({
      personContextId: propTypes.number,
    }),
  }).isRequired,
};
