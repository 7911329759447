import { useContext, useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { useFormikContext } from 'formik';
import * as yup from 'yup';
import { useLazyQuery, useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import {
  getUnityDwellerSearch,
  insertStepHistory,
} from '@kiper/monitoring-graphql/guided_attendance';
import { eventTriage } from '@kiper/monitoring-graphql/attendance';
import { useDebounce, useSwal } from '@kiper/hooks';
import { apolloErrorHandler } from '@kiper/fns';
import { ErrorMessage, Flex, SearchGroupSelect } from '@kiper/ui';
import { MdSearch } from 'react-icons/md';
import { useGuidedAttendance } from '../../../hooks';
import { GuidedAttendanceContext, TriageContext } from '../../../store';

import StepGetUnityEmptyState from './StepGetUnityEmptyState';
import StepStructure, { StepTextTypeColor } from '../StepStructure';

import { Label } from '../styles';

const StepAttendanceGetUnity = ({ event }) => {
  const { setContextTree, contextTree } = useContext(GuidedAttendanceContext);
  const { setTriageContext } = useContext(TriageContext);
  const { handleNextAction, handleGetTriageSelected } = useGuidedAttendance();
  const {
    values,
    setFieldValue,
    errors,
    touched,
    handleSubmit,
  } = useFormikContext();
  const { t } = useTranslation('guided-attendance');
  const [search, setSearch] = useState('');
  const [unities, setUnities] = useState([]);
  const [startLoadingOptions, setStartLoadingOptions] = useState(true);
  const { toast } = useSwal();

  const debounce = useDebounce({ delay: 500 });

  const [getUnities, { loading }] = useLazyQuery(getUnityDwellerSearch, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ condominiumUnityDwellerSearch }) => {
      setUnities(condominiumUnityDwellerSearch?.unities);
      setStartLoadingOptions(false);
    },
  });

  const [updateTriage] = useMutation(eventTriage, {
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const [stepHistory] = useMutation(insertStepHistory, {
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const schema = yup.object({
    stepAttendanceGetUnity: yup.object({
      unity: yup
        .object({
          id: yup.number().required(t('common:feedback.required-field')),
          description: yup.string(),
          treeNodeId: yup.number(),
          dwellers: yup
            .array()
            .of(
              yup.object({
                id: yup.number(),
                name: yup.string(),
                personContextId: yup.number(),
              }),
            )
            .required(),
        })
        .required(t('common:feedback.required-field')),
      unityGroup: yup
        .object({
          id: yup.number(),
          description: yup.string(),
          treeNodeId: yup.number(),
        })
        .required(t('common:feedback.required-field')),
    }),
  });

  const handleSelect = option => {
    const unity = { ...option };
    const unityGroup = { ...option?.unityGroup };

    if (unity?.unityGroup) delete unity?.unityGroup;

    setTriageContext({
      filterGUUnit: {
        gu: {
          label: option?.unityGroup?.description,
          treeNodeId: option?.unityGroup?.treeNodeId,
        },
        label: option?.description,
        treeNodeId: option?.treeNodeId,
        parentName: option?.unityGroup?.description,
        value: `${option?.treeNodeId} ${option?.unityGroup?.description} ${option?.description}`,
      },
    });

    setFieldValue('stepAttendanceGetUnity', {
      unity,
      unityGroup,
    });

    setSearch(option.description);
  };

  const handleSearch = inputValue => {
    setSearch(inputValue);
    setStartLoadingOptions(true);

    if (inputValue?.length >= 1) {
      debounce(() => {
        getUnities({
          variables: {
            condominiumContextId: Number(event?.condominium?.personContextId),
            search: inputValue,
          },
        });
      });
    } else {
      setUnities([]);
    }
  };

  const handleClear = () => {
    setSearch('');
    setStartLoadingOptions(true);
    setFieldValue('stepAttendanceGetUnity', {
      ...values.stepAttendanceGetUnity,
      unity: {},
      unityGroup: {},
    });
  };

  const handleUpdateTriage = value => {
    if (!value?.stepAttendanceGetUnity) return null;

    const variables = {
      eventId: event?.eventId,
      triageId: value?.stepAttendanceGetReason?.id,
    };

    const { stepAttendanceGetUnity } = value || {};
    const serializedTriageJson = stepAttendanceGetUnity?.unity?.treeNodeId
      ? { guUnTreeNodeId: stepAttendanceGetUnity.unity.treeNodeId }
      : {};

    if (value?.stepAttendanceGetName?.name)
      serializedTriageJson.identifier = value.stepAttendanceGetName.name;

    if (Object.keys(serializedTriageJson).length > 0)
      variables.serializedTriage = JSON.stringify(serializedTriageJson);

    return updateTriage({
      variables,
    });
  };

  const handleStepHistory = (unity, unityGroup) => {
    const eventId = event?.eventId;
    const stepId = contextTree?.currentStep?.id;
    const data = {
      unityId: unity?.id,
      unity: unity?.description,
      unityGroup: unityGroup?.description,
    };

    return stepHistory({
      variables: {
        eventId,
        stepId,
        data: JSON.stringify(data),
      },
    });
  };

  const nextAction = async value => {
    const { stepAttendanceGetUnity } = value;
    const { unity, unityGroup } = stepAttendanceGetUnity;

    Promise.all([
      handleUpdateTriage(value),
      handleStepHistory(unity, unityGroup),
    ]);

    const handleNextFn = await handleNextAction({
      values: value,
      fieldValue: 'stepAttendanceGetUnity',
    });

    return handleNextFn;
  };

  const showEmptyState = useMemo(
    () => !loading && !unities.length && !startLoadingOptions,
    [loading, unities, startLoadingOptions],
  );

  const { hasUnityId, hasDwellers } = useMemo(
    () => ({
      hasUnityId: !!values?.stepAttendanceGetUnity?.unity?.id,
      hasDwellers: !!values?.stepAttendanceGetUnity?.unity?.dwellers?.length,
    }),
    [values?.stepAttendanceGetUnity],
  );

  useEffect(() => {
    const hasTouched = !!touched?.stepAttendanceGetUnity;

    const showErrorToast = hasTouched && hasUnityId && !hasDwellers;

    if (showErrorToast)
      toast.fire({
        title: t('step-get-unity.empty-state.alert'),
        icon: 'error',
      });
  }, [touched?.stepAttendanceGetUnity, values?.stepAttendanceGetUnity]);

  useEffect(() => {
    const hasUnitySelectedWithDwellers = hasUnityId && hasDwellers;

    if (hasUnitySelectedWithDwellers) handleSubmit();
  }, [values?.stepAttendanceGetUnity?.unity]);

  useEffect(() => {
    if (!values?.stepAttendanceGetUnity)
      setFieldValue('stepAttendanceGetUnity', {
        unity: {},
        unityGroup: {},
      });
    else handleSearch(values?.stepAttendanceGetUnity?.unity?.description);

    setContextTree({
      formikSchema: schema,
      nextAction: async stepData => {
        const nextStep = await nextAction(stepData);
        return { nextStep, currentStepData: stepData };
      },
    });
  }, []);

  return (
    <StepStructure
      type={StepTextTypeColor.SPEAK}
      text={t('step-get-unity.title', {
        triage: t(`step-get-unity.triage.${handleGetTriageSelected(values)}`),
      })}
      hasPreviousStepButton
    >
      <Flex flexDirection="column" width="100%">
        <Label>{t('step-get-unity.label')}</Label>
        <SearchGroupSelect
          name="search"
          icon={<MdSearch />}
          options={unities}
          loadingOptions={loading}
          placeholder={t('step-get-unity.placeholder')}
          showEmptyState={showEmptyState}
          emptyState={
            <StepGetUnityEmptyState
              title={t('step-get-unity.empty-state.unit-not-found.title')}
              text={t('step-get-unity.empty-state.unit-not-found.text')}
              optionsNotFound
            />
          }
          emptyStateDwellersText={t(
            'step-get-unity.empty-state.dwellers-not-found',
          )}
          loadingStateText={t('step-get-unity.loading-text')}
          optionsTitle={t('step-get-unity.unity')}
          secondColumnTitle={t('step-get-unity.dwellers')}
          onSearch={handleSearch}
          onSelect={handleSelect}
          onClear={handleClear}
          value={search}
          selectValue={values?.stepAttendanceGetUnity}
          error={
            !!touched?.stepAttendanceGetUnity &&
            (!!errors?.stepAttendanceGetUnity?.unity?.id ||
              !!errors?.stepAttendanceGetUnity?.unity?.dwellers)
          }
        />

        {!!touched?.stepAttendanceGetUnity &&
          !!errors?.stepAttendanceGetUnity?.unity?.id && (
            <ErrorMessage>
              {errors?.stepAttendanceGetUnity?.unity?.id}
            </ErrorMessage>
          )}

        {!!touched?.stepAttendanceGetUnity &&
          !!values?.stepAttendanceGetUnity?.unity?.id &&
          !!errors?.stepAttendanceGetUnity?.unity?.dwellers && (
            <StepGetUnityEmptyState
              title={t(
                'step-get-unity.empty-state.unit-without-dwellers.title',
              )}
              text={t('step-get-unity.empty-state.unit-without-dwellers.text')}
            />
          )}
      </Flex>
    </StepStructure>
  );
};

export default StepAttendanceGetUnity;

StepAttendanceGetUnity.propTypes = {
  event: propTypes.shape({
    eventId: propTypes.string,
    condominium: propTypes.shape({
      personContextId: propTypes.number,
    }),
  }),
};

StepAttendanceGetUnity.defaultProps = {
  event: null,
};
