import gql from 'graphql-tag';
import { dwellerAccessMediaTypesAvailableFragment } from '../fragments';
import { placeTemporaryAccessFragment } from './fragments';

export const dweller = gql`
  query dweller($personId: Int!) {
    dweller(personId: $personId) {
      personId
      name
      email
      phone
      creationDate
      canChangeEmail
      devices {
        id
        type
        value
        counter
        lastCounter
        blocked
        description
        lastAccessDateTime
        lastAcessPlace
        facialStatus
        fingerIndex
        finger
        panic
        mobile {
          os
          version
          name
          gadgetInfo
        }
      }
      accesses {
        id
        profile {
          value
          label
        }
        condominium {
          value
          label
          personContextId
          treeNodeId
        }
        unityGroup {
          value
          label
          placeTypeLabelId
          treeNodeId
        }
        unity {
          value
          label
          placeTypeLabelId
          treeNodeId
        }
        typeUnityGroup {
          value
          label
        }
        typeUnity {
          value
          label
        }
        accessProfiles {
          value
          label
          isInvite
        }
        placeTemporaryAccess {
          ...placeTemporaryAccessParts
        }
        allowedAccesses {
          start {
            hours
            minutes
            time
          }
          end {
            hours
            minutes
            time
          }
          daysOfWeek
          gmt
        }
        contactSchedule {
          start {
            hours
            minutes
            time
          }
          end {
            hours
            minutes
            time
          }
          daysOfWeek
          gmt
        }
        permissions {
          id
          name
          applicationFeatureId
          ableToRead
          ableToWrite
          allowsEdit
          fieldName
        }
        accessibilityTime
        accessPeriod {
          start
          end
        }
        callPriority
        observation
        bookingTime {
          initDate
          endDate
        }
        contactOrder {
          main
          intercom
          additional
        }
        isBlocked
        blockedReason
        blockedByPersonContextId
      }
      documents {
        id
        value
        documentTypeId
      }
      additionalContacts {
        id
        personId
        contactTypeId
        contact
      }
    }
  }
  ${placeTemporaryAccessFragment}
`;

export const documentTypes = gql`
  query documentTypes {
    documentTypes {
      id
      name
      origin
      mask
    }
  }
`;

export const expectedGuestsByDweller = gql`
  query($personContextId: Int!, $filters: GuestQueryInput) {
    expectedGuestsByDweller(
      personContextId: $personContextId
      filters: $filters
    ) {
      collection {
        inviteId
        hostName
        createPersonInvite
        inviteName
        startDate
        endDate
      }
      pagination {
        totalPages
        totalResults
        page
        pageSize
      }
    }
  }
`;

export const dwellerAccessMediaTypesAvailable = gql`
  query($personId: Int!, $isUpdate: Boolean) {
    dwellerAccessMediaTypesAvailable(personId: $personId, isUpdate: $isUpdate) {
      ...dwellerAccessMediaTypesAvailableParts
    }
  }
  ${dwellerAccessMediaTypesAvailableFragment}
`;

export const accessProfilesByDweller = gql`
  query accessProfilesByDweller($personContextId: Int!) {
    accessProfilesByDweller(personContextId: $personContextId) {
      id
      name
    }
  }
`;

export const vehiclesByDweller = gql`
  query vehiclesByDweller($personId: Int!) {
    vehiclesByDweller(personId: $personId) {
      id
      plate
      model
      type
      tag
      creationDate
    }
  }
`;
