import * as yup from 'yup';

const ONLY_NUMBER_REGEX = /^\d+$/;

const documentsType = Object.freeze({
  CPF: 'cpf',
  RG: 'rg',
  PHONE: 'phone',
});

const useValidationSchema = ({ t }) => {
  const initialSchema = {
    dweller: yup
      .object({
        id: yup.number().required(t('step-dweller-validation.feedback-error')),
        name: yup.string(),
        documents: yup
          .array()
          .of(
            yup.object({
              type: yup.string(),
              value: yup.string(),
            }),
          )
          .nullable(),
      })
      .required(),
    dwellerValidationStatus: yup.number().required(),
  };

  const invalidDwellerValidationSchema = {
    dweller: yup.object({ id: yup.number(), name: yup.string() }).nullable(),
    dwellerValidationStatus: yup.number().required(),
  };

  const validationSchema = schema =>
    yup.object({
      stepDwellerValidation: yup.object(schema),
    });

  const handleMaxCharacters = documentType => {
    switch (documentType) {
      case documentsType.PHONE:
        return 4;
      case documentsType.CPF:
        return 3;
      case documentsType.RG:
        return 3;
      default:
        return 3;
    }
  };

  function documentValidator(value) {
    const { dweller } = this.parent;
    const documentType = this.path.split('.')[1];
    const documentTypeValue = dweller?.documents?.find(
      document => document.type === documentType,
    )?.value;

    return value === documentTypeValue;
  }

  const generateFieldSchema = numberOfChars =>
    yup
      .string()
      .trim()
      .min(numberOfChars)
      .max(numberOfChars)
      .matches(ONLY_NUMBER_REGEX, t('common:feedback.only-numbers'))
      .test('invalid-document', 'invalid-document', documentValidator)
      .required();

  const handleGetValidationSchema = fields => {
    if (!fields.length) return null;

    const schema = {};

    fields.forEach(field => {
      const { type } = field;
      if (type) {
        const numberOfChars = handleMaxCharacters(type);
        schema[type] = generateFieldSchema(numberOfChars);
      }
    });

    return validationSchema({ ...initialSchema, ...schema });
  };

  return {
    handleMaxCharacters,
    handleGetValidationSchema,
    invalidDwellerValidationSchema: validationSchema(
      invalidDwellerValidationSchema,
    ),
    schema: validationSchema(initialSchema),
  };
};

export default useValidationSchema;
