import { useContext, useEffect } from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';
import { GuidedAttendanceContext } from '../../../../store';

const useForm = ({ nextAction }) => {
  const { setContextTree } = useContext(GuidedAttendanceContext);
  const { setFieldValue, handleSubmit } = useFormikContext();

  const schema = yup.object({
    stepOutOfHoursServiceProvider: yup.object({
      finishGuidedAttendance: yup.bool(),
    }),
  });

  useEffect(() => {
    setFieldValue('stepOutOfHoursServiceProvider', {
      finishGuidedAttendance: true,
    });

    setContextTree({
      formikSchema: schema,
      nextAction,
    });
  }, []);

  return { handleSubmit };
};

export default useForm;
