import {
  Col as col,
  Card as card,
  CardHeader as cardheader,
  CardBody as cardbody,
  Button as button,
  FormGroup as formgroup,
  Row as row,
  Form as form,
} from 'reactstrap';
import { Label as label, H5 as h5 } from '@kiper/ui';
import styled, { css } from 'styled-components';

export const BlockFullWidth = styled.div``;
export const Card = styled(card)`
  flex: 1;
  border-radius: 8px;

  ${props =>
    props.border
      ? css`
          border-color: ${props.border};
        `
      : null}

  span {
    opacity: ${props => (props.$isBlocked ? 0.5 : 1)};
  }
`;

export const CardHeader = styled(cardheader)`
  background: transparent;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardBody = styled(cardbody)`
  padding: 20px 10px;
`;

export const Button = styled(button)``;

export const FormGroup = styled(formgroup)`
  margin-bottom: 16px;
`;

export const Form = styled(form)`
  padding: 0;
`;

export const Row = styled(row)`
  margin: 0;
`;

export const Flex = styled.div`
  display: flex;
  ${props =>
    props.justify &&
    css`
      justify-content: ${props.justify};
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `}
`;

export const H5 = styled(h5)`
  margin-bottom: 0;
`;

export const MainInfoContainer = styled.div`
  display: flex;
  ${props =>
    props.isEdition &&
    css`
      margin-bottom: 15px;
    `}
`;

export const AvatarContainer = styled(col)`
  display: flex;
  justify-content: center;
`;

export const Col = styled(col)`
  padding: ${props => (props.separator ? '0 15px 0 0' : '0')};
  & + & {
    padding: 0;
  }
`;

export const Optional = styled(label)`
  margin-left: 5px;
  color: ${props => props.theme.colors.secondary400};
`;

export const AddMore = styled.button`
  background: inherit;
  padding: 15px 0;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border: none;
  justify-content: center;
`;

export const AddMoreContainer = styled(col)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  border-radius: 4px;
  background-color: inherit;
  height: 100%;
  border: ${props =>
    `1px ${props.theme.colors.secondary400} ${
      props.device ? 'solid' : 'dashed'
    }`};

  & + & {
    margin-left: 10px;
  }

  img {
    margin-bottom: 10px;
  }
`;

export const FooterActions = styled(Flex)`
  justify-content: flex-end;
  padding: 0 15px 15px 0;
  position: fixed;
  right: 0;
  bottom: 0;
`;

export const DeviceContainer = styled.div``;

export const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const LoaderWrapper = styled(card)`
  height: 160px;
`;
