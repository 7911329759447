import { useGuidedAttendance } from '../../../../hooks';

const useNextAction = () => {
  const { handleNextAction } = useGuidedAttendance();
  const nextAction = async stepData => {
    const handleNextFn = await handleNextAction({
      values: stepData,
      fieldValue: 'stepOutOfHoursServiceProvider',
    });

    return handleNextFn;
  };

  return { nextAction };
};

export default useNextAction;
